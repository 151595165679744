import axios from 'axios'

class API {
  constructor(baseURL, estateNavigatorURL) {
    this.baseURL = baseURL
    this.estateNavigatorURL = estateNavigatorURL
    this.instance = axios.create({
      baseURL: baseURL,
    })
    this.estateNavigatorInstance = axios.create({
      baseURL: estateNavigatorURL,
    })
  }

  sendContactRequest = async (contactData) => {
    return this.instance.post(`/contact/aprest`, contactData)
  }

  sendAgentPersonalEmailRequest = async (agentId, contactData) => {
    return this.instance.post(`/contact/agent/${agentId}`, contactData)
  }

  sendMailWithAttachment = async (contactData) => {
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
    return this.instance.post('/contact/aprest/attachments', contactData, config)
  }

  sendRegisterPropertyRequest = async (contactData) => {
    return this.instance.post(`/contact/aprest/register-property`, contactData)
  }

  sendLookingForRequest = async (contactData) => {
    return this.instance.post(`/contact/aprest/looking-for`, contactData)
  }

  sendCreditRequest = async (contactData) => {
    return this.instance.post(`/contact/aprest-credit`, contactData)
  }

  sendAgentPersonalEmailRequest = async (agentId, contactData) => {
    return this.instance.post(`/contact/${agentId}`, contactData)
  }

  getOffers = async (pageSize, page) => {
    return this.estateNavigatorInstance.get(
      `/offers?paginate=true&page_size=${pageSize}&page=${page}`,
    )
  }

  getOffer = async (symbol) => {
    return this.estateNavigatorInstance.get(`/offers?symbol=${symbol}`)
  }

  searchOffers = async (payload) => {
    return this.estateNavigatorInstance.post('/offers/search', payload)
  }

  getStreets = async () => {
    return this.estateNavigatorInstance.get('/offers/street')
  }
  getAgentOffers = async (pageSize, page, agent) => {
    return this.estateNavigatorInstance.get(
      `/offers?paginate=true&page_size=${pageSize}&page=${page}&agent=${agent}`,
    )
  }
}

const api = new API('https://api.onitsoft.com', 'https://xrest-api.onitsoft.com/v1')

export default api
