import React from 'react'
import { useTranslation } from 'react-i18next'
import { motion } from 'framer-motion'
import {
  Header,
  TextBox,
  HeroContent,
  RealEstate,
  Offer,
  About,
  Container,
  Photos,
  AboutContent,
  HomeIcon,
  CoinIcon,
  TeamIcon,
  CheckIcon,
  LookFor,
  Cta,
  Card,
  Content,
  Our,
  Row,
  OurCol,
  InfoContainer,
  TextContainer,
} from './homeElements'
import OfferCard from './offerCard'
import API from '../../api'
import img20 from '../../assets/images/img20.jpg'
import img21 from '../../assets/images/img21.jpg'
import img22 from '../../assets/images/img22.jpg'

const Home = () => {
  const { t } = useTranslation()
  const [isSuccess, setIsSuccess] = React.useState(false)
  const [offers, setOffers] = React.useState(null)

  React.useEffect(() => {
    API.getOffers(3, 1).then((res) => {
      setOffers(res.data.response)
      setIsSuccess(true)
    })
  }, [])

  const headerVariants = {
    hidden: { opacity: 0, y: -50 },
    visible: { opacity: 1, y: 0, transition: { duration: 1 } },
  }

  const textVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 1, delay: 0.5 } },
  }

  const imageVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: { opacity: 1, scale: 1, transition: { duration: 1, delay: 1 } },
  }

  return (
    <>
      <Header>
        <TextBox>
          <HeroContent
            as={motion.div}
            initial='hidden'
            animate='visible'
            variants={headerVariants}
          >
            <h2>{t('home.title')}</h2>
            <p>{t('home.subtitle')}</p>
            <RealEstate>
              <a href={'/offer'}>{t('home.find_property')}</a>
            </RealEstate>
          </HeroContent>
        </TextBox>
      </Header>
      {isSuccess && offers && (
        <>
          <Offer
            as={motion.div}
            initial='hidden'
            animate='visible'
            variants={textVariants}
          >
            <h1>{t('home.highlighted')}</h1>
            <span />
            <div>
              {offers
                .slice(0, 3)
                .reverse()
                .map((elem, index) => (
                  <OfferCard
                    info={elem}
                    key={index}
                  />
                ))}
              <div />
              <LookFor
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '1rem',
                }}
              >
                <a href={'/offer'}>{t('home.learn_more')}</a>
              </LookFor>
            </div>
          </Offer>
        </>
      )}
      <About>
        <Container
          id='about-us'
          as={motion.div}
          initial='hidden'
          animate='visible'
          variants={headerVariants}
        >
          <Photos
            as={motion.div}
            initial='hidden'
            animate='visible'
            variants={imageVariants}
          >
            <div />
            <span />
          </Photos>
          <AboutContent>
            <h1>APREST</h1>
            <h2>{t('home.about_us_title')}</h2>
            <p>{t('home.about_us_description')}</p>
            <div>
              <div>
                <div>
                  <div>
                    <HomeIcon />
                  </div>
                  <p>{t('home.about_us_dot1')}</p>
                </div>
                <div>
                  <div>
                    <CoinIcon />
                  </div>
                  <p>{t('home.about_us_dot2')}</p>
                </div>
              </div>
              <div>
                <div>
                  <div>
                    <TeamIcon />
                  </div>
                  <p>{t('home.about_us_dot3')}</p>
                </div>
                <div>
                  <div>
                    <CheckIcon />
                  </div>
                  <p>{t('home.about_us_dot4')}</p>
                </div>
              </div>
            </div>
            <section>
              <span />
              <p>{t('home.about_us_summary')}</p>
            </section>
            <LookFor>
              <a href={'/offer'}>{t('home.learn_more')}</a>
            </LookFor>
          </AboutContent>
        </Container>
      </About>

      {/* <InfoContainer
        as={motion.div}
        initial='hidden'
        animate='visible'
        variants={textVariants}
      >
        <TextContainer>
          <h2>{t('home.info')}</h2>
          <p>{t('home.text_info')}</p>
          <a href={'/offer'}>{t('home.find_property')}</a>
        </TextContainer>
      </InfoContainer> */}
      <Our>
        <h1>{t('home.about_us2_title')}</h1>
        <p>{t('home.about_us2_subtitle')}</p>
        <Row>
          <OurCol
            as={motion.div}
            initial='hidden'
            animate='visible'
            variants={imageVariants}
          >
            <img src={img20} />
            <h3>{t('home.about_us2_col1_title')}</h3>
            <p>{t('home.about_us2_col1_subtitle')}</p>
          </OurCol>
          <OurCol
            as={motion.div}
            initial='hidden'
            animate='visible'
            variants={imageVariants}
          >
            <img src={img21} />
            <h3>{t('home.about_us2_col2_title')}</h3>
            <p>{t('home.about_us2_col2_subtitle')}</p>
          </OurCol>
          <OurCol
            as={motion.div}
            initial='hidden'
            animate='visible'
            variants={imageVariants}
          >
            <img src={img22} />
            <h3>{t('home.about_us2_col3_title')}</h3>
            <p>{t('home.about_us2_col3_subtitle')}</p>
          </OurCol>
        </Row>
      </Our>
      <Cta>
        <Card
          as={motion.div}
          initial='hidden'
          animate='visible'
          variants={headerVariants}
        >
          <Content>
            <h2>{t('home.summary_title')}</h2>
            <p>{t('home.summary_subtitle')}</p>
          </Content>
          <a href={'/offer'}>{t('home.more')}</a>
        </Card>
      </Cta>
    </>
  )
}

export default Home
