import React from 'react'
import styled from 'styled-components'

const PrivacyPolicy = () => {
  return (
    <Container>
      <Section>
        <p>
          <strong>1. Informacje ogólne</strong>
          <br />
          Niniejsza Polityka Prywatności określa zasady przetwarzania i ochrony danych osobowych
          przekazanych przez Użytkowników w związku z korzystaniem przez nich z usług oferowanych
          przez Aprest Sp. z o.o. za pośrednictwem serwisu internetowego{' '}
          <a href='https://aprest.pl/'>https://aprest.pl/</a>.
        </p>
      </Section>

      <Section>
        <p>
          <strong>2. Administrator danych osobowych</strong>
          <br />
          Administratorem danych osobowych jest Aprest Sp. z o.o. z siedzibą w Krakowie, ul.
          Cystersów 13A/1, 31-553 Kraków, NIP: 6751785588.
        </p>
      </Section>

      <Section>
        <p>
          <strong>3. Zakres zbieranych danych</strong>
          <br />W ramach działalności serwisu możemy zbierać następujące dane osobowe Użytkowników:
        </p>
        <ul>
          <li>Dane kontaktowe: imię, nazwisko, adres e-mail, numer telefonu.</li>
          <li>Dane techniczne: adres IP, typ urządzenia, typ przeglądarki, system operacyjny.</li>
          <li>Pliki cookies: informacje dotyczące aktywności Użytkownika w serwisie.</li>
        </ul>
      </Section>

      <Section>
        <p>
          <strong>4. Cel przetwarzania danych</strong>
          <br />
          Zebrane dane osobowe przetwarzane są w celu:
        </p>
        <ul>
          <li>Realizacji usług pośrednictwa w obrocie nieruchomościami.</li>
          <li>Umożliwienia kontaktu z Użytkownikami.</li>
          <li>Dostosowania treści serwisu do preferencji Użytkowników.</li>
          <li>Prowadzenia analiz statystycznych dotyczących korzystania z serwisu.</li>
        </ul>
      </Section>

      <Section>
        <p>
          <strong>5. Podstawa prawna przetwarzania danych</strong>
          <br />
          Dane osobowe przetwarzane są na podstawie:
        </p>
        <ul>
          <li>Zgody Użytkownika – w przypadku danych przekazywanych dobrowolnie.</li>
          <li>Niezbędności przetwarzania do wykonania umowy.</li>
          <li>Prawnie uzasadnionego interesu Administratora.</li>
        </ul>
      </Section>

      <Section>
        <p>
          <strong>6. Udostępnianie danych</strong>
          <br />
          Dane osobowe Użytkowników mogą być udostępniane:
        </p>
        <ul>
          <li>Podmiotom współpracującym z Aprest Sp. z o.o.</li>
          <li>
            Organom uprawnionym do uzyskania danych na podstawie obowiązujących przepisów prawa.
          </li>
        </ul>
      </Section>

      <Section>
        <p>
          <strong>7. Prawa Użytkowników</strong>
          <br />
          Użytkownikom przysługują następujące prawa:
        </p>
        <ul>
          <li>Prawo dostępu do swoich danych.</li>
          <li>Prawo do sprostowania danych.</li>
          <li>Prawo do usunięcia danych („prawo do bycia zapomnianym”).</li>
          <li>Prawo do ograniczenia przetwarzania.</li>
          <li>Prawo do przenoszenia danych.</li>
          <li>Prawo do wniesienia sprzeciwu wobec przetwarzania.</li>
          <li>Prawo do cofnięcia zgody.</li>
          <li>Prawo do wniesienia skargi do organu nadzorczego.</li>
        </ul>
      </Section>

      <Section>
        <p>
          <strong>8. Pliki cookies</strong>
          <br />
          Serwis wykorzystuje pliki cookies w celu:
        </p>
        <ul>
          <li>Dostosowania zawartości serwisu do preferencji Użytkownika.</li>
          <li>Tworzenia statystyk korzystania z serwisu.</li>
          <li>Utrzymania sesji Użytkownika.</li>
        </ul>
      </Section>
    </Container>
  )
}

// Styled Components
const Container = styled.div`
  max-width: 900px;
  margin: 60px auto;
  padding: 100px 20px 20px;
  color: #666;
  font-size: 16px;
  line-height: 1.6;
`

const Section = styled.div`
  margin-bottom: 20px;

  a {
    color: #007bff;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  ul {
    margin-top: 10px;
    padding-left: 20px;
  }

  li {
    margin-bottom: 5px;
  }
`

export default PrivacyPolicy
