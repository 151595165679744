import styled from 'styled-components'

export const Container = styled.div`
  position: fixed;
  bottom: 10px;
  left: 10px;
  right: 10px;
  background-color: #222;
  padding: 15px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
  font-size: 14px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 20px;
  }
`

export const Content = styled.div`
  display: flex;
  align-items: center;
  flex: 1;

  @media (max-width: 768px) {
    flex-direction: column;
    margin-bottom: 10px;
  }
`

export const Icon = styled.div`
  display: flex;
  align-items: center;

  svg {
    font-size: 24px;
    color: #c0a36a;
    margin-right: 10px;
  }

  @media (max-width: 768px) {
    svg {
      font-size: 30px;
      margin-bottom: 10px;
    }
  }
`

export const Text = styled.p`
  margin: 0;
  flex: 1;
  color: #fff;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`

export const PrivacyLink = styled.a`
  color: #c0a36a;
  text-decoration: underline;
  font-weight: bold;

  &:hover {
    text-decoration: none;
  }
`

export const AcceptButton = styled.button`
  margin-left: 15px;
  background-color: #c0a36a;
  color: #ffffff;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 5px;
  font-weight: bold;

  &:hover {
    background-color: #b2955d;
  }

  @media (max-width: 768px) {
    width: 100%;
    padding: 12px;
    font-size: 16px;
  }
`