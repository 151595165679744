// Navbar.js
import { MenuItems } from './MenuItems'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Nav,
  Logo,
  NavMenu,
  Img,
  BurgerMenu,
  Drawer,
  NavItem,
  NavLink,
  DrawerLink,
  LogoContainer,
  NavContainer,
} from './navbarElements'

const Navbar = () => {
  const { t } = useTranslation()
  const [isScrolled, setIsScrolled] = useState(false)
  const [activeItem, setActiveItem] = useState('/')
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset
      setIsScrolled(scrollTop > 50)
    }

    const setActivePath = () => {
      const currentPath = window.location.pathname + window.location.hash
      setActiveItem(currentPath)
    }

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768)
    }

    window.addEventListener('scroll', handleScroll)
    window.addEventListener('resize', handleResize)
    handleResize() // Check initial size
    setActivePath()

    return () => {
      window.removeEventListener('scroll', handleScroll)
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen)
    // Prevent scrolling when drawer is open
    document.body.style.overflow = isDrawerOpen ? 'auto' : 'hidden'
  }

  const closeDrawer = () => {
    setIsDrawerOpen(false)
    document.body.style.overflow = 'auto'
  }

  return (
    <Nav isScrolled={isScrolled}>
      <NavContainer>
        <LogoContainer>
          <Logo href={'/'}>
            <Img
              src='/Logo_COLOR1.png'
              alt='Logo'
              isScrolled={isScrolled}
            />
          </Logo>
        </LogoContainer>

        <NavMenu>
          {MenuItems.map((item, index) => {
            const isActive = activeItem === item.url
            return (
              <NavItem
                key={index}
                isActive={isActive}
              >
                <NavLink
                  href={item.url}
                  isActive={isActive}
                  onClick={() => setActiveItem(item.url)}
                >
                  {t(item.dict_key)}
                </NavLink>
              </NavItem>
            )
          })}
        </NavMenu>

        <BurgerMenu
          onClick={toggleDrawer}
          isOpen={isDrawerOpen}
          aria-label='Menu'
        >
          <span />
          <span />
          <span />
        </BurgerMenu>

        <Drawer
          isOpen={isDrawerOpen}
          onClick={toggleDrawer}
        >
          <div
            className='drawer-content'
            onClick={(e) => e.stopPropagation()}
          >
            {MenuItems.map((item, index) => {
              const isActive = activeItem === item.url
              return (
                <DrawerLink
                  href={item.url}
                  key={index}
                  isActive={isActive}
                  onClick={() => {
                    setActiveItem(item.url)
                    closeDrawer()
                  }}
                >
                  {t(item.dict_key)}
                </DrawerLink>
              )
            })}
          </div>
        </Drawer>
      </NavContainer>
    </Nav>
  )
}

export default Navbar
