import React, { useState, useEffect } from 'react'
import { FaCookieBite } from 'react-icons/fa'
import { Container, Content, Icon, Text, PrivacyLink, AcceptButton } from './cookiesElements'

const CookiesConsent = () => {
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    const cookiesAccepted = localStorage.getItem('cookiesAccepted')
    if (!cookiesAccepted) {
      setIsVisible(true)
    }
  }, [])

  const acceptCookies = () => {
    localStorage.setItem('cookiesAccepted', 'true')
    setIsVisible(false)
  }

  if (!isVisible) {
    return null
  }

  return (
    <Container>
      <Content>
        <Icon>
          <FaCookieBite />
        </Icon>
        <Text>
          Używamy plików cookies w celu poprawy jakości usług i analizy ruchu na stronie.
          Korzystając z serwisu, zgadzasz się na naszą{' '}
          <PrivacyLink href='/polityka-prywatnosci'>Politykę Prywatności</PrivacyLink>.
        </Text>
      </Content>
      <AcceptButton onClick={acceptCookies}>Akceptuję</AcceptButton>
    </Container>
  )
}

export default CookiesConsent
