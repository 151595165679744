import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  FaFacebookSquare,
  FaInstagram,
  FaYoutube,
  FaPhone,
  FaEnvelope,
  FaMapMarkerAlt,
} from 'react-icons/fa'
import Logo_WHITE from '../../assets/images/Logo_WHITE.png'
import Cookies from 'js-cookie'
import {
  FooterWrapper,
  MainFooter,
  FooterContainer,
  FooterColumn,
  FooterLogo,
  SectionHeading,
  SubText,
  ContactButton,
  FooterTitle,
  FooterList,
  FooterListItem,
  LanguageSelector,
  LanguageOption,
  PrivacyLink,
  SocialMediaContainer,
  SocialIcon,
  PoweredBy,
  Copyright,
  DesignedBy,
} from './footerElements'

const Footer = () => {
  const currentYear = new Date().getFullYear()
  const { t, i18n } = useTranslation()
  const currentLanguage = i18n.language

  const handleLanguageChange = (language) => {
    i18n.changeLanguage(language)
    Cookies.set('lang', language, { expires: 365 })
  }

  return (
    <FooterWrapper>
      <MainFooter>
        <FooterContainer>
          <FooterColumn>
            <FooterLogo>
              <img
                src={Logo_WHITE}
                alt='Aprest Logo'
              />
            </FooterLogo>
            <SectionHeading>{t('footer.title')}</SectionHeading>
            <SubText>{t('footer.subtitle')}</SubText>
            <ContactButton href='/contact'>{t('footer.contact_us')}</ContactButton>
          </FooterColumn>

          <FooterColumn>
            <FooterTitle>{t('footer.info')}</FooterTitle>
            <FooterList>
              <FooterListItem>
                <FaMapMarkerAlt />
                <span>Cystersów 13A/1, 31-553 Kraków</span>
              </FooterListItem>
              <FooterListItem>
                <span>NIP: 6751785588</span>
              </FooterListItem>
            </FooterList>

            <FooterTitle style={{ marginTop: '2rem' }}>{t('footer.language')}</FooterTitle>
            <LanguageSelector>
              <LanguageOption
                active={currentLanguage === 'pl'}
                onClick={() => handleLanguageChange('pl')}
              >
                Polski
              </LanguageOption>
              <LanguageOption
                active={currentLanguage === 'en'}
                onClick={() => handleLanguageChange('en')}
              >
                English
              </LanguageOption>
            </LanguageSelector>

            <PrivacyLink href='/polityka-prywatnosci'>Polityka Prywatności</PrivacyLink>
          </FooterColumn>

          <FooterColumn>
            <FooterTitle>{t('footer.contact')}</FooterTitle>
            <FooterList>
              <FooterListItem>
                <FaPhone />
                <a href='tel:48501858805'>+48 501 858 805</a>
              </FooterListItem>
              <FooterListItem>
                <FaEnvelope />
                <a href='mailto:info@aprest.pl'>info@aprest.pl</a>
              </FooterListItem>
            </FooterList>

            <FooterTitle style={{ marginTop: '2rem' }}>Social Media</FooterTitle>
            <SocialMediaContainer>
              <SocialIcon
                href='https://www.facebook.com/profile.php?id=61551918651411'
                target='_blank'
                aria-label='Facebook'
              >
                <FaFacebookSquare />
              </SocialIcon>
              <SocialIcon
                href='https://instagram.com/aprest_realestate?igshid=OGQ5ZDc2ODk2ZA=='
                target='_blank'
                aria-label='Instagram'
              >
                <FaInstagram />
              </SocialIcon>
              <SocialIcon
                href='https://www.youtube.com/channel/UCbmPLHwUikpsjNIKcHXjGLQ'
                target='_blank'
                aria-label='YouTube'
              >
                <FaYoutube />
              </SocialIcon>
            </SocialMediaContainer>
          </FooterColumn>
        </FooterContainer>
      </MainFooter>

      <PoweredBy>
        <a
          href='http://virgo.galactica.pl/'
          target='_blank'
          rel='noopener noreferrer'
          title='oprogramowanie dla biur nieruchomości'
        >
          POWERED BY VIRGO API
        </a>
      </PoweredBy>
      <DesignedBy>
        <span>
          Designed by{' '}
          <a
            href='https://onitsoft.com'
            target='_blank'
            rel='noopener noreferrer'
          >
            OnitSoft
          </a>
        </span>
      </DesignedBy>
      <Copyright>
        <span>Copyright © {currentYear} Aprest Nieruchomości</span>
      </Copyright>
    </FooterWrapper>
  )
}

export default Footer
