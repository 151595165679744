import styled, { keyframes } from 'styled-components'
import { FaBed, FaRegSquare, FaSnowflake, FaWifi } from 'react-icons/fa'
import { BsChevronCompactRight, BsChevronCompactLeft } from 'react-icons/bs'
import { PiElevatorBold } from 'react-icons/pi'
import { BiSolidCctv } from 'react-icons/bi'
import { AiOutlineClose } from 'react-icons/ai'

export const RouterIcon = styled(FaWifi)`
  font-size: 1.4rem;
  margin: 0 0.6rem;
  position: relative;
  top: -0.2rem;

  & > path {
    fill: #9f9e9e;
  }
`

export const AcIcon = styled(FaSnowflake)`
  font-size: 1.4rem;
  margin: 0 0.6rem;
  position: relative;
  top: -0.1rem;

  & > path {
    fill: #9f9e9e;
  }
`

export const SolidCameraIcon = styled(BiSolidCctv)`
  font-size: 1.4rem;
  margin: 0 0.6rem;
  position: relative;
  top: -0.2rem;

  & > path {
    fill: #9f9e9e;
  }
`

export const ElevatorIcon = styled(PiElevatorBold)`
  font-size: 1.4rem;
  margin: 0 0.6rem;
  position: relative;
  top: -0.1rem;

  & > path {
    fill: #9f9e9e;
  }
`

export const BedIcon = styled(FaBed)`
  font-size: 1.4rem;
  margin: 0 0.6rem;
  position: relative;
  top: -0.2rem;

  & > path {
    fill: #9f9e9e;
  }
`

export const SquareIcon = styled(FaRegSquare)`
  font-size: 1.4rem;
  margin: 0 0.6rem;
  position: relative;
  top: -0.1rem;

  & > path {
    fill: #9f9e9e;
  }
`

export const RightIcon = styled(BsChevronCompactRight)`
  font-size: 3rem;
  position: absolute;
  top: calc(50% - 1.5rem);
  left: ${(props) => `calc(${(props.scrolledBy + 1) * 100}% - 4.5rem)`};
  cursor: pointer;
  padding: 0.3rem;
  border: 1px solid #fff;
  border-radius: 50%;

  & > path {
    fill: #c0a36a;
  }

  @media (max-width: 1024px) {
    left: ${(props) => `calc(95vw + ${props.scrolledBy * 100}% - 4.5rem)`};
  }
`

export const LeftIcon = styled(BsChevronCompactLeft)`
  font-size: 3rem;
  position: absolute;
  top: calc(50% - 1.5rem);
  left: ${(props) => `calc(${props.scrolledBy * 100}% + 1.5rem)`};
  cursor: pointer;
  padding: 0.3rem;
  border: 1px solid #fff;
  border-radius: 50%;

  & > path {
    fill: #c0a36a;
  }

  @media (max-width: 1024px) {
    left: ${(props) => `calc(${props.scrolledBy * 100}% + 1.5rem)`};
  }
`
export const WrapperAgenent = styled.section`
  width: 400px;
`

export const Wrapper = styled.section`
  & > h2 {
    margin: 2rem 5rem;
    text-transform: uppercase;
    color: #8b8b8b;
    font-size: 1.4rem;
    font-weight: 400;
  }

  & > span {
    display: block;
    width: 100%;
    height: 2px;
    margin-bottom: 2rem;
    background-color: #ddd;
  }
`

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: 120px 5rem 2rem 5rem;
  justify-content: center;

  @media (max-width: 1024px) {
    flex-direction: column;
    margin: 120px 0 0 0;
  }
`

export const Header = styled.div`
  width: 60vw;
  height: 70vh;
  white-space: nowrap;
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  position: relative;

  @media (max-width: 1024px) {
    width: 95vw;
    margin: 0 auto;
  }

  & > div {
    width: 100%;
    height: 100%;

    & > div {
      display: inline-block;
      width: 100%;
      height: 100%;
    }
  }
`

export const Title = styled.div`
  padding: 1rem 3rem;

  & h3 {
    padding: 0 1rem 0.3rem 1rem;
    margin-top: 0.3rem;
    font-weight: 400;
    letter-spacing: 0.03rem;
    color: #000;
  }
`

export const TitleBar = styled.div`
  width: 100vw;
  padding: 1rem 3rem;
  display: flex;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;

  & > div {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &:last-of-type {
      align-items: flex-end;

      @media (max-width: 1024px) {
        align-items: center;
      }
    }

    & > div {
      display: grid;
      grid-template-columns: repeat(2, 2fr);
      grid-column-gap: 3rem;
      grid-row-gap: 1rem;

      & > p {
        display: flex;

        & > span {
          flex-grow: 1;
        }
      }

      @media (max-width: 1024px) {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }

  @media (max-width: 1024px) {
    flex-direction: column;
  }

  & h3 {
    padding: 0 1rem 0.3rem 1rem;
    margin-top: 0.3rem;
    font-weight: 400;
    letter-spacing: 0.03rem;
    color: #000;
  }
`

export const Price = styled.div`
  display: flex;

  & h2 {
    font-size: 1.2rem;
    margin-top: 0.3rem;
    letter-spacing: 0.03rem;
  }

  & > div {
    display: flex;

    & > div {
      display: flex;
    }

    & p {
      font-size: 1.1rem;
    }
  }
`

export const Content = styled.section`
  padding: 0rem 5rem 2rem 5rem;
  display: flex;

  & ul {
    position: relative;
    left: 1rem;
  }

  @media (max-width: 1024px) {
    flex-direction: column;
    padding: 0rem 1rem 2rem 1rem;

    & > div {
      max-width: 100vw;
    }
  }
`

export const Agent = styled.div`
  width: 400px;
  display: flex;
  align-items: center;

  @media (max-width: 1024px) {
    margin: 1rem auto;
    width: 60vw;
    margin: 2rem auto;

    & > img {
      min-width: 0%;
    }
  }
`

export const Photos = styled.div`
  padding: 2rem 5rem;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 1rem;
  cursor: pointer;

  & > a > img {
    width: 100%;
    margin-top: 1rem;
  }

  @media (max-width: 1024px) {
    grid-template-columns: repeat(1, 1fr);
  }
`

const spinnerAnimation = keyframes`
    100% {
        transform:rotate(360deg)
    }
`

export const Spinner = styled.path`
  transform-origin: center;
  fill: #c0a36a;
  animation: ${spinnerAnimation} 0.6s linear infinite;
`

export const LoadingWrapper = styled.div`
  width: 100vw;
  height: 100vh;
`

export const SpinnerWrapper = styled.div`
  width: 60vw;
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const MapWrapper = styled.div`
  width: 90%;
  height: 400px;
  margin: 0 auto 2rem auto;
`

export const PhotoPop = styled.section`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(20px);
  z-index: 1004;

  @supports not (backdrop-filter: blur(0)) {
    backdrop-filter: none;
    background-color: #000;
  }

  & > section {
    background-color: #000;
    position: absolute;
    width: 100dvw;
    height: 100dvh;
    z-index: 1003;
    opacity: 0.7;
  }

  & > img {
    height: auto;
    max-width: 80vw;
    user-select: none;
    z-index: 1005;
  }
`

export const PopRightIcon = styled(BsChevronCompactRight)`
  font-size: 3rem;
  position: fixed;
  top: calc(50% - 1.5rem);
  right: 1.5rem;
  cursor: pointer;
  padding: 0.3rem;
  border: 1px solid #fff;
  border-radius: 50%;
  z-index: 1006;

  & > path {
    fill: #c0a36a;
  }
`

export const PopLeftIcon = styled(BsChevronCompactLeft)`
  font-size: 3rem;
  position: fixed;
  top: calc(50% - 1.5rem);
  left: 1.5rem;
  cursor: pointer;
  padding: 0.3rem;
  border: 1px solid #fff;
  border-radius: 50%;
  z-index: 1006;

  & > path {
    fill: #c0a36a;
  }
`

export const ClosePopIcon = styled(AiOutlineClose)`
  font-size: 3rem;
  position: fixed;
  top: 1.5rem;
  right: 1.5rem;
  cursor: pointer;
  padding: 0.3rem;
  border: 1px solid #fff;
  border-radius: 50%;
  z-index: 1006;

  & > path {
    fill: #c0a36a;
  }
`
