import styled from 'styled-components'

export const FooterWrapper = styled.footer`
  background-color: #161927;
  color: #fff;
  font-family: 'Poppins', sans-serif;
`

export const MainFooter = styled.div`
  padding: 4rem 0;
  background-color: #161927;
`

export const FooterContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 3rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    text-align: center;
  }
`

export const FooterColumn = styled.div`
  display: flex;
  flex-direction: column;
`

export const FooterLogo = styled.div`
  margin-bottom: 1.5rem;

  img {
    height: 80px;
    width: auto;
    margin-bottom: 1rem;
  }

  h2 {
    font-size: 1.8rem;
    font-weight: 600;
    margin: 0.5rem 0;
    color: #fff;
  }

  p {
    color: rgba(255, 255, 255, 0.9);
    line-height: 1.6;
    margin-top: 1rem;
  }
`

export const ContactButton = styled.a`
  display: inline-block;
  background-color: #c0a36a;
  color: #161927;
  padding: 0.8rem 2rem;
  margin-top: 1.5rem;
  border-radius: 50px;
  font-weight: 600;
  text-decoration: none;
  transition: all 0.3s ease;
  border: 2px solid #c0a36a;

  &:hover {
    background-color: transparent;
    color: #c0a36a;
    transform: translateY(-3px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  }
`

export const FooterTitle = styled.h3`
  color: #c0a36a;
  font-size: 1.3rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -10px;
    width: 50px;
    height: 2px;
    background-color: #c0a36a;

    @media (max-width: 768px) {
      left: 50%;
      transform: translateX(-50%);
    }
  }
`

export const FooterList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`

export const FooterListItem = styled.li`
  margin-bottom: 1rem;
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    justify-content: center;
  }

  svg {
    margin-right: 0.8rem;
    color: #c0a36a;
  }

  a,
  span {
    color: rgba(255, 255, 255, 0.9);
    text-decoration: none;
    transition: color 0.3s ease;

    &:hover {
      color: #c0a36a;
    }
  }
`

export const SocialMediaContainer = styled.div`
  display: flex;
  gap: 1.5rem;
  margin-top: 1rem;

  @media (max-width: 768px) {
    justify-content: center;
  }
`

export const SocialIcon = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: #c0a36a;
  color: #161927;
  font-size: 1.5rem;
  transition: all 0.3s ease;

  &:hover {
    background-color: #fff;
    color: #c0a36a;
    transform: translateY(-3px);
    box-shadow: 0 5px 15px rgba(192, 163, 106, 0.4);
  }
`

export const LanguageSelector = styled.div`
  display: flex;
  margin-top: 1rem;

  @media (max-width: 768px) {
    justify-content: center;
  }
`

export const LanguageOption = styled.span`
  cursor: pointer;
  margin-right: 1.5rem;
  color: ${(props) => (props.active ? '#c0a36a' : 'rgba(255, 255, 255, 0.9)')};
  font-weight: ${(props) => (props.active ? '600' : '400')};
  transition: color 0.3s ease;
  padding-bottom: 3px;
  border-bottom: ${(props) => (props.active ? '2px solid #c0a36a' : '2px solid transparent')};

  &:hover {
    color: #c0a36a;
  }
`

export const PrivacyLink = styled.a`
  color: rgba(255, 255, 255, 0.9);
  text-decoration: none;
  margin-top: 2rem;
  display: inline-block;
  transition: color 0.3s ease;

  &:hover {
    color: #c0a36a;
    text-decoration: underline;
  }
`

export const PoweredBy = styled.div`
  text-align: center;
  padding: 1rem 0;
  background-color: #161927;

  a {
    color: #c0a36a;
    text-decoration: none;
    font-weight: 500;
    transition: color 0.3s ease;

    &:hover {
      color: #fff;
      text-decoration: underline;
    }
  }
`

export const Copyright = styled.div`
  text-align: center;
  padding: 1.5rem 0;
  background-color: #161927;
  color: rgba(255, 255, 255, 0.7);
`

export const DesignedBy = styled.div`
  text-align: center;
  padding: 1rem 0;
  background-color: #161927;
  color: rgba(255, 255, 255, 0.7);

  a {
    color: #c0a36a;
    text-decoration: none;
    font-weight: 600;
    transition: color 0.3s ease;

    &:hover {
      color: #fff;
      text-decoration: underline;
    }
  }
`

export const SectionHeading = styled.h2`
  color: #fff;
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
`

export const SubText = styled.p`
  color: rgba(255, 255, 255, 0.9);
  line-height: 1.6;
  margin-bottom: 2rem;
`
