import styled, { keyframes } from 'styled-components'

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`

export const NavContainer = styled.div`
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;

  @media (max-width: 768px) {
    padding: 0 10px;
  }
`

export const LogoContainer = styled.div`
  z-index: 1000;
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    max-width: 70%;
  }
`

export const Nav = styled.nav`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 30px;
  box-shadow: ${(props) => (!props.isScrolled ? 'none' : '0 10px 30px 0 rgba(0, 0, 0, .08)')};
  background-color: ${(props) =>
    !props.isScrolled ? 'rgba(255, 255, 255, 0.95)' : 'rgba(255, 255, 255, 0.98)'};
  backdrop-filter: blur(10px);
  position: fixed;
  top: 0;
  min-height: ${(props) => (props.isScrolled ? '80px' : '110px')};
  width: 100%;
  z-index: 1000;
  transition: all 0.3s ease;

  @media (max-width: 768px) {
    padding: 0 15px;
    min-height: ${(props) => (props.isScrolled ? '60px' : '80px')};
  }
`

export const Logo = styled.a`
  color: rgb(69, 69, 69);
  cursor: pointer;
  display: flex;
  align-items: center;

  &:hover {
    transform: scale(1.02);
    transition: transform 0.3s ease;
  }
`

export const NavMenu = styled.ul`
  list-style-type: none;
  display: flex;
  margin: 0;
  padding: 0;

  @media (max-width: 1024px) {
    display: none;
  }
`

export const NavItem = styled.li`
  position: relative;
  display: inline-block;
  margin: 0 5px;

  &::after {
    content: '';
    position: absolute;
    width: ${(props) => (props.isActive ? '100%' : '0')};
    height: 2px;
    bottom: -5px;
    left: 0;
    background-color: #c0a36a;
    transition: width 0.3s ease;
  }

  &:hover::after {
    width: 100%;
  }
`

export const NavLink = styled.a`
  display: block;
  padding: 10px 15px;
  text-decoration: none;
  cursor: pointer;
  color: ${(props) => (props.isActive ? '#c0a36a' : 'rgb(69, 69, 69)')};
  font-weight: ${(props) => (props.isActive ? '600' : '500')};
  transition: all 0.2s ease;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-size: 14px;

  &:hover {
    color: #c0a36a;
  }
`

export const Img = styled.img`
  display: flex;
  align-items: center;
  height: ${(props) => (props.isScrolled ? '80px' : '120px')};
  width: auto;
  filter: drop-shadow(2px 2px 4px rgba(32, 30, 29, 0.15));
  transition: all 0.3s ease;

  @media (max-width: 768px) {
    height: ${(props) => (props.isScrolled ? '50px' : '70px')};
    max-width: 100%;
    object-fit: contain;
  }
`

export const BurgerMenu = styled.div`
  display: none;
  width: 40px;
  height: 30px;
  cursor: pointer;
  z-index: 1001;
  position: relative;

  & > span {
    display: block;
    width: 100%;
    height: 2px;
    background-color: #c0a36a;
    position: absolute;
    left: 0;
    transition: all 0.3s ease;

    &:first-of-type {
      top: ${(props) => (props.isOpen ? '50%' : '0')};
      transform: ${(props) => (props.isOpen ? 'rotate(45deg)' : 'rotate(0)')};
    }

    &:nth-child(2) {
      top: 50%;
      transform: translateY(-50%);
      opacity: ${(props) => (props.isOpen ? 0 : 1)};
    }

    &:last-of-type {
      bottom: ${(props) => (props.isOpen ? '50%' : '0')};
      transform: ${(props) => (props.isOpen ? 'rotate(-45deg)' : 'rotate(0)')};
    }
  }

  @media (max-width: 1024px) {
    display: block;
  }
`

export const Drawer = styled.div`
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: ${(props) => (props.isOpen ? 1 : 0)};
  visibility: ${(props) => (props.isOpen ? 'visible' : 'hidden')};
  transition: all 0.4s ease;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.98);
  backdrop-filter: blur(15px);
  z-index: 1000;
  animation: ${fadeIn} 0.3s ease-in-out;

  .drawer-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
  }
`

export const DrawerLink = styled.a`
  color: ${(props) => (props.isActive ? '#c0a36a' : 'rgb(69, 69, 69)')};
  letter-spacing: 1px;
  font-size: 1.8rem;
  font-weight: ${(props) => (props.isActive ? '600' : '500')};
  text-decoration: none;
  margin: 1rem 0;
  position: relative;
  transition: all 0.3s ease;
  text-transform: uppercase;

  &::after {
    content: '';
    position: absolute;
    width: ${(props) => (props.isActive ? '100%' : '0')};
    height: 2px;
    bottom: -5px;
    left: 0;
    background-color: #c0a36a;
    transition: width 0.3s ease;
  }

  &:hover {
    color: #c0a36a;
    transform: translateY(-2px);

    &::after {
      width: 100%;
    }
  }

  @media (max-width: 768px) {
    font-size: 1.5rem;
    margin: 0.8rem 0;
  }
`
