import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import API from '../../../../api'

// Styled components dla przycisków i formularza
export const AgentActions = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 1rem;
  gap: 1rem;

  @media (max-width: 1024px) {
    width: 90%;
    margin: 1rem auto;
  }
`

export const ActionButton = styled.button`
  background-color: #c0a36a;
  color: white;
  border: none;
  padding: 0.8rem 1rem;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #a08956;
  }
`

export const ContactForm = styled.form`
  display: ${(props) => (props.isVisible ? 'flex' : 'none')};
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  background-color: #f7f7f7;
  border: 1px solid #ddd;
  margin-top: 0.5rem;
`

export const FormInput = styled.input`
  padding: 0.8rem;
  border: 1px solid #ddd;
  font-size: 1rem;

  &:focus {
    outline: 1px solid #c0a36a;
  }
`

export const FormTextarea = styled.textarea`
  padding: 0.8rem;
  border: 1px solid #ddd;
  font-size: 1rem;
  min-height: 120px;
  resize: vertical;

  &:focus {
    outline: 1px solid #c0a36a;
  }
`

export const FormSubmit = styled.button`
  background-color: #c0a36a;
  color: white;
  border: none;
  padding: 0.8rem 1rem;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  align-self: flex-end;

  &:hover {
    background-color: #a08956;
  }
`

export const StatusMessage = styled.div`
  padding: 0.5rem;
  font-size: 0.9rem;
  margin-top: 0.5rem;
  text-align: center;
  color: ${(props) => (props.success ? '#27ae60' : '#e74c3c')};
`

// Komponent funkcjonalny dla akcji agenta
const AgentActionsComponent = ({ agentId, agentName }) => {
  const { t } = useTranslation()
  const [showContactForm, setShowContactForm] = useState(false)
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  })
  const [formStatus, setFormStatus] = useState({
    submitted: false,
    success: false,
    message: '',
  })

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setFormData({
      ...formData,
      [name]: value,
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    setFormStatus({
      submitted: true,
      success: false,
      message: 'Wysyłanie wiadomości...',
    })

    try {
      // Przygotuj dane formularza
      const contactData = {
        ...formData,
        agentId: agentId,
        subject: `Wiadomość do agenta ${agentName || 'Aprest'}`,
      }

      // Wywołaj API do wysłania wiadomości
      await API.sendAgentPersonalEmailRequest(agentId, contactData)

      setFormStatus({
        submitted: true,
        success: true,
        message: 'Dziękujemy! Twoja wiadomość została wysłana.',
      })

      // Wyczyść formularz po pomyślnym wysłaniu
      setFormData({
        name: '',
        email: '',
        phone: '',
        message: '',
      })

      // Opcjonalnie schowaj formularz po 3 sekundach
      setTimeout(() => {
        setShowContactForm(false)
        setFormStatus({
          submitted: false,
          success: false,
          message: '',
        })
      }, 3000)
    } catch (error) {
      console.error('Błąd podczas wysyłania formularza:', error)
      setFormStatus({
        submitted: true,
        success: false,
        message: 'Wystąpił błąd podczas wysyłania wiadomości. Spróbuj ponownie później.',
      })
    }
  }

  const handleSeeMoreOffers = () => {
    // Przekierowanie do strony z ofertami agenta
    window.location.href = `/agent/${agentId}`
  }

  return (
    <AgentActions>
      <ActionButton onClick={() => setShowContactForm(!showContactForm)}>
        {t('agent.contact')}
      </ActionButton>

      <ContactForm
        isVisible={showContactForm}
        onSubmit={handleSubmit}
      >
        <FormInput
          type='text'
          name='name'
          placeholder={t('agent.form.name')}
          value={formData.name}
          onChange={handleInputChange}
          required
        />
        <FormInput
          type='email'
          name='email'
          placeholder={t('agent.form.email')}
          value={formData.email}
          onChange={handleInputChange}
          required
        />
        <FormInput
          type='tel'
          name='phone'
          placeholder={t('agent.form.phone')}
          value={formData.phone}
          onChange={handleInputChange}
        />
        <FormTextarea
          name='message'
          placeholder={t('agent.form.message')}
          value={formData.message}
          onChange={handleInputChange}
          required
        />
        <FormSubmit type='submit'>{t('agent.form.send')}</FormSubmit>

        {formStatus.submitted && (
          <StatusMessage success={formStatus.success}>{formStatus.message}</StatusMessage>
        )}
      </ContactForm>

      <ActionButton onClick={handleSeeMoreOffers}>{t('agent.see_more_offers')}</ActionButton>
    </AgentActions>
  )
}

export default AgentActionsComponent
