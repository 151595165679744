import * as React from 'react'
import { useTranslation } from 'react-i18next'
import styled, { keyframes } from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { AiFillCamera } from 'react-icons/ai'
import { BiSolidFilm } from 'react-icons/bi'
import { FaBed, FaRegSquare } from 'react-icons/fa'
import { getAgentFromId } from '../../lib/AgentCard'

const BedIcon = styled(FaBed)`
  font-size: 1.2rem;
  margin: 0 0.4rem;
  position: relative;
  top: 0.3rem;

  & > path {
    fill: #ddd;
  }
`

const SquareIcon = styled(FaRegSquare)`
  font-size: 1.2rem;
  margin: 0 0.4rem;
  position: relative;
  top: 0.3rem;

  & > path {
    fill: #ddd;
  }
`

const Wrapper = styled.div`
  margin: 0.7rem;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  height: 100%;
  border: 1px solid #ddd;
  transition: all 0.1s linear;
  z-index: 900;
  background-color: #fff;

  & > h3 {
    padding: 0 1rem 0.3rem 1rem;
    margin-top: 0.3rem;
    font-weight: lighter;
    letter-spacing: 0.03rem;
    color: #8b8b8b;
  }

  & > span {
    display: block;
    width: 100%;
    height: 1.2px;
    background-color: #ddd;
  }

  @media (max-width: 720px) {
    max-width: 100%;
  }

  &:hover {
    transform: scale(1.01);
  }
`

const DescriptionBox = styled.div`
  padding: 1rem 1rem 0 1rem;
  background-color: #fff;

  & > * {
    margin-top: 1rem;
  }

  & h2 {
    margin-top: 0.3rem;
    font-weight: 400;
  }

  & > h3 {
    color: #8b8b8b;
    font-size: 1.2rem;
    font-weight: lighter;
    margin-top: 0.3rem;
    letter-spacing: 0.03rem;
  }

  & > p {
    text-align: justify;
  }
`

const Price = styled.div`
  display: flex;
  padding: 0.5rem 1rem;

  & h2 {
    font-size: 1.2rem;
    margin-top: 0.3rem;
    letter-spacing: 0.03rem;
  }

  & > div {
    display: flex;

    & > div {
      display: flex;
    }

    & p {
      font-size: 1.1rem;
    }

    & > span {
      display: block;
      height: 100%;
      width: 1.2px;
      margin: 0 0.7rem;
      background-color: #ddd;
    }
  }
`

const ContactBox = styled.div`
  display: flex;
  padding: 0.5rem;
  align-items: center;

  & > div {
    display: flex;
    flex-direction: column;
  }

  & > img {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 20px;
    margin-right: 1rem;
  }
`

const Cover = ({ ...props }) => {
  const Wrapper = styled.div`
    position: relative;
    width: 100%;
    height: 18rem;
    box-shadow: 0px 2px 6px 1px rgba(32, 30, 29, 0.2);
    overflow: hidden;

    & > section {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center;
      transform-origin: center center;
      transition: all 0.3s linear;
    }

    & > span {
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;
      display: block;
      width: fit-content;
      color: #fff;
      padding: 0.2rem 0.8rem;
    }

    & > div {
      position: absolute;
      bottom: 0;
      padding: 0.3rem;
      display: flex;
      width: 100%;

      & > p {
        color: #fff;
      }

      & > span {
        display: flex;
        flex-grow: 1;
        justify-content: flex-end;

        & > p {
          margin: 0 0.1rem;
          color: #fff;
        }
      }
    }
  `

  const CameraIcon = styled(AiFillCamera)`
    margin-right: 0.1rem;
    position: relative;
    top: 0.1rem;

    & > path {
      fill: #fff;
    }
  `

  const TapeIcon = styled(BiSolidFilm)`
    margin-right: 0.1rem;

    & > path {
      fill: #fff;
    }
  `

  const spinnerAnimation = keyframes`
        100% {
            transform:rotate(360deg)
        }
    `

  const Spinner = styled.path`
    transform-origin: center;
    fill: #c0a36a;
    animation: ${spinnerAnimation} 0.6s linear infinite;
  `

  const SpinnerWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  `

  const [imageReady, setImageReady] = React.useState(false)
  React.useEffect(() => {
    const image = new Image()
    image.src = `https://xrest-api.onitsoft.com/v1/offers/photo?photo_id=${props.info.photos[0].virgo_id}&basic_watermark=true&additional_watermark=false&photo_size=2000_1333`

    image.onload = () => {
      setImageReady(true)
    }

    image.onerror = () => {
      setImageReady(false)
    }
  }, [props.info.photos])

  return (
    <Wrapper>
      {imageReady ? (
        <>
          <section
            style={{
              backgroundImage: `url(https://xrest-api.onitsoft.com/v1/offers/photo?photo_id=${props.info.photos[0].virgo_id}&basic_watermark=true&additional_watermark=false&photo_size=2000_1333)`,
            }}
          />
          <div>
            <span>
              <p>
                <CameraIcon />
                {props.info.photos.length}
              </p>
            </span>
          </div>
        </>
      ) : (
        <SpinnerWrapper>
          <svg
            width='100'
            height='100'
            viewBox='0 0 24 24'
            xmlns='http://www.w3.org/2000/svg'
          >
            <Spinner d='M2,12A11.2,11.2,0,0,1,13,1.05C12.67,1,12.34,1,12,1a11,11,0,0,0,0,22c.34,0,.67,0,1-.05C6,23,2,17.74,2,12Z' />
          </svg>
        </SpinnerWrapper>
      )}
    </Wrapper>
  )
}

const OfferCard = ({ ...props }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <Wrapper onClick={() => navigate(`/offer/${props.info.symbol}`)}>
      <Cover info={props.info} />
      <DescriptionBox>
        <h3>
          {props.info.location}, {props.info.street}
        </h3>
        <h2>{props.info.offer_title}</h2>
      </DescriptionBox>
      <div style={{ flex: 1 }} />
      <Price>
        <div>
          <div>
            <p>
              <BedIcon />
              {props.info.number_of_rooms}
            </p>
          </div>
          <span />
        </div>
        <div>
          <div>
            <p>
              <SquareIcon />
              {props.info.total_area} m<sup>2</sup>
            </p>
          </div>
        </div>
        <div style={{ flex: 1 }} />
        <div>
          <h2>
            {parseInt(props.info.price).toLocaleString('pl-PL', {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })}{' '}
            zł
          </h2>
        </div>
      </Price>
      <h3>{props.info.rent ? t('offer.for_rent') : t('offer.for_sale')}</h3>
      <span />
      <ContactBox>
        <AgentBar agentId={props.info.agent} />
      </ContactBox>
    </Wrapper>
  )
}

export default OfferCard

const AgentBar = ({ agentId }) => {
  const { t } = useTranslation()
  const agentInformation = getAgentFromId(agentId)
  let agentImage
  try {
    agentImage = `https://onitsoft-static-apps.s3.eu-central-1.amazonaws.com/aprest-nieruchomosci/agents/${agentId}.webp`
  } catch (error) {
    agentImage = require('../../assets/images/logo_placeholder.png')
  }

  return (
    <>
      <img src={agentImage} />
      <div>
        <p>{agentInformation.name}</p>
        <p style={{ fontSize: '.8rem' }}>{t(`contact.${agentInformation.titleKey}`)}</p>
      </div>
      <span style={{ display: 'flex', flexGrow: 1 }} />
    </>
  )
}
