import styled from 'styled-components'
import img5 from '../../assets/images/img5.jpg'

export const SubHeader2 = styled.div`
  min-height: 50vh;
  width: 100%;
  background-image: linear-gradient(rgba(4, 9, 30, 0.7), rgba(4, 9, 30, 0.7)), url(${img5});
  background-position: center;
  background-size: cover;
  position: relative;
  text-align: center;
  color: #fff;

  & h1 {
    font-weight: 300;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    margin: 0;
    color: #fff;
  }

  @media (max-width: 768px) {
    min-height: 40vh;
  }
`

export const ContactUs = styled.div`
  width: 80%;
  margin: auto;

  @media (max-width: 768px) {
    width: 90%;
  }
`

export const Row = styled.div`
  margin-top: 5%;
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`

export const ContactCol = styled.div`
  flex-basis: 48%;
  margin-bottom: 30px;

  & input,
  & textarea {
    width: 100%;
    padding: 15px;
    margin-bottom: 17px;
    outline: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-family: inherit;
    font-size: 14px;
  }

  & button {
    display: inline-block;
    text-decoration: none;
    color: #fff;
    border: 1px solid #c0a36a;
    padding: 12px 34px;
    font-size: 13px;
    background: #c0a36a;
    position: relative;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    border-radius: 4px;
  }

  & button:hover {
    border: 1px solid #c0a36a;
    background: #fff;
    color: #c0a36a;
  }

  @media (max-width: 768px) {
    flex-basis: 100%;
  }
`

export const Coc = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 40px;

  & span {
    flex: 1;
  }

  & p {
    padding: 0;
    margin: 0;
    color: #777;
  }

  & h5 {
    font-size: 20px;
    margin-bottom: 5px;
    color: #555;
    font-weight: 400;
    margin-top: 0;
  }

  & a {
    display: inline-block;
    text-decoration: none;
    color: #fff;
    border: 1px solid #c0a36a;
    padding: 12px 34px;
    font-size: 13px;
    background: #c0a36a;
    position: relative;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    border-radius: 4px;
    min-width: 187px;
    text-align: center;
  }

  & a:hover {
    border: 1px solid #c0a36a;
    background: #fff;
    color: #c0a36a;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;

    & a {
      width: 100%;
      padding: 10px 20px;
      min-width: unset;
    }
  }
`

export const Coc1 = styled.div`
  margin-bottom: 40px;

  & a {
    display: inline-block;
    text-decoration: none;
    color: #fff;
    border: 1px solid #c0a36a;
    padding: 12px 34px;
    font-size: 13px;
    background: #c0a36a;
    position: relative;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    min-width: 187px;
    text-align: center;
    border-radius: 4px;
  }

  & a:hover {
    border: 1px solid #c0a36a;
    background: #fff;
    color: #c0a36a;
  }

  @media (max-width: 768px) {
    & a {
      width: 100%;
      padding: 10px 20px;
      min-width: unset;
    }
  }
`

export const Location = styled.div`
  width: 100%;
  margin: auto;
  padding: 80px 0;

  & iframe {
    width: 100%;
    height: 450px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  @media (max-width: 768px) {
    padding: 40px 0;

    & iframe {
      height: 300px;
    }
  }
`

export const Team = styled.section`
  padding: 40px 0;

  & > h1 {
    font-size: 1.5rem;
    color: rgb(29, 38, 54);
    text-align: center;
    margin-bottom: 3rem;
    font-weight: 300;
  }

  & > section {
    margin: 0 auto;
    width: 90%;
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));

    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }
  }
`

export const Text = styled.p`
  background-color: rgba(13, 157, 75, 0.94);
  color: white;
  padding: 12px;
  margin-bottom: 20px;
  text-align: center;
  border-radius: 4px;
  font-weight: 500;

  @media (max-width: 768px) {
    padding: 10px;
    font-size: 14px;
  }
`

export const TextForm = styled.p`
  background-color: rgba(13, 157, 75, 0.94);
  color: white;
  padding: 12px;
  margin-bottom: 20px;
  text-align: center;
  border-radius: 4px;
  font-weight: 500;

  @media (max-width: 768px) {
    padding: 10px;
    font-size: 14px;
  }
`

export const Text1 = styled.div`
  width: 80%;
  margin: auto;
  text-align: center;
  padding: 60px 0;

  & h1 {
    color: rgb(69, 69, 69);
    font-size: 36px;
    font-weight: 300;
    margin-bottom: 20px;
  }

  & hr {
    width: 60px;
    margin: 20px auto;
    border: none;
    border-top: 2px solid #c0a36a;
  }

  & p {
    color: #777;
    font-weight: 300;
    line-height: 1.8;
    font-size: 18px;
    max-width: 800px;
    margin: 0 auto;
  }

  @media (max-width: 768px) {
    width: 90%;
    padding: 40px 0;

    & h1 {
      font-size: 24px;
    }

    & p {
      font-size: 16px;
      line-height: 1.6;
    }
  }
`
