import styled from 'styled-components'

export const PageContainer = styled.div`
  font-family: 'Arial', sans-serif;
  max-width: 100%;
  margin: 0 auto;
  margin-top: 100px;
`

export const AgentContainer = styled.div`
  display: flex;
  padding: 40px;
  max-width: 1200px;
  margin: 0 auto;
  gap: 40px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`

export const AgentImageSection = styled.div`
  flex: 0 0 45%;
`

export const AgentImage = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
`

export const AgentInfoSection = styled.div`
  flex: 1;
`

export const AgentRole = styled.div`
  color: #555;
  margin-bottom: 10px;
`

export const AgentName = styled.h1`
  font-size: 32px;
  font-weight: 500;
  margin-bottom: 20px;
`

export const ContactRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
`

export const AgentPhone = styled.div`
  font-size: 20px;
  margin-right: 15px;
`

export const ShowNumberButton = styled.button`
  background-color: #f2f2f2;
  border: none;
  padding: 5px 10px;
  font-size: 14px;
  cursor: pointer;
`

export const AgentEmail = styled.div`
  margin-bottom: 20px;
`

export const ContactButton = styled.button`
  background-color: #c0a36a;
  color: white;
  border: none;
  padding: 12px 24px;
  font-size: 16px;
  cursor: pointer;
  display: inline-block;
`

export const Divider = styled.hr`
  margin: 30px 0;
  border: none;
  border-top: 1px solid #eee;
`

export const AgentBio = styled.div`
  line-height: 1.6;

  p {
    margin-bottom: 15px;
  }
`

export const OffersSection = styled.section`
  padding: 40px;
  max-width: 1200px;
  margin: 0 auto;
`

export const SectionTitle = styled.h2`
  font-size: 36px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 40px;
`

export const OffersGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 30px;
`

export const SeeAllButton = styled.button`
  display: block;
  margin: 40px auto 0;
  background-color: #c0a36a;
  color: white;
  border: none;
  padding: 12px 24px;
  cursor: pointer;
`

export const LoadingContainer = styled.div`
  text-align: center;
  padding: 50px;
  font-size: 18px;
`
// Dodatkowe stylowane komponenty
export const ProfileHeader = styled.div`
  padding: 40px;
  background-color: #f8f8f8;
`

export const ProfileContainer = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`

export const AgentDetailsSection = styled.div`
  flex: 1;
  padding: 10px;
`

// Komponenty dla paginacji
export const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 30px 0;
  gap: 10px;
`

export const PageButton = styled.button`
  padding: 8px 15px;
  background-color: ${(props) => (props.active ? '#c0a36a' : 'white')};
  color: ${(props) => (props.active ? 'white' : '#c0a36a')};
  border: 1px solid #c0a36a;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    background-color: ${(props) => (props.active ? '#c0a36a' : '#f0f0f0')};
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`

// Komponenty dla formularza kontaktowego
export const ContactFormSection = styled.section`
  max-width: 1200px;
  margin: 40px auto;
  padding: 0 20px;
`

export const ContactForm = styled.form`
  background-color: #f8f8f8;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`

export const FormTitle = styled.h3`
  margin-bottom: 20px;
  font-size: 24px;
  color: #c0a36a;
  text-align: center;
`

export const FormRow = styled.div`
  display: flex;
  gap: 20px;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 10px;
  }
`

export const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

export const FormLabel = styled.label`
  margin-bottom: 8px;
  font-weight: 500;
`

export const FormInput = styled.input`
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
`

export const FormTextarea = styled.textarea`
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  min-height: 150px;
  resize: vertical;
`

export const SubmitButton = styled.button`
  padding: 12px 24px;
  background-color: #c0a36a;
  align-items: center;
  display: flex;
  justify-content: center;
  color: white;
  border: none;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s;
  margin: 0 auto;

  &:hover {
    background-color: #a28956;
  }
`
