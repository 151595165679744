import React, { useEffect, useState, useReducer, useRef } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import API from '../../api'
import OfferCard from '../Home/offerCard'
import styled from 'styled-components'
import {
  PageContainer,
  AgentImageSection,
  AgentImage,
  AgentRole,
  AgentName,
  ContactRow,
  AgentPhone,
  ShowNumberButton,
  AgentEmail,
  ContactButton,
  Divider,
  AgentBio,
  OffersSection,
  SectionTitle,
  OffersGrid,
  SeeAllButton,
  LoadingContainer,
  PaginationContainer,
  PageButton,
  ProfileHeader,
  ProfileContainer,
  AgentDetailsSection,
  ContactFormSection,
  ContactForm,
  FormTitle,
  FormRow,
  FormGroup,
  FormLabel,
  FormInput,
  FormTextarea,
  SubmitButton,
} from './agentElemetns'
import agents from '../../lib/AgentCard/agents.json'
import i18next from 'i18next'

// Funkcja pomocnicza – pobiera dane agenta z JSON na podstawie agentId
export const getAgentFromId = (agentId) => {
  return agents[agentId] ?? agents['undefined']
}

// Reducer dla paginacji ofert
const offersReducer = (state, action) => {
  switch (action.type) {
    case 'setOffers':
      return {
        ...state,
        offers: action.payload.data,
        totalCount: action.payload.count,
        loading: false,
      }
    case 'setLoading':
      return {
        ...state,
        loading: action.payload,
      }
    case 'changePage':
      return {
        ...state,
        currentPage: action.payload,
        loading: true,
      }
    default:
      return state
  }
}

const AgentProfile = () => {
  const { t } = useTranslation()
  const { agentId } = useParams()
  const navigate = useNavigate()
  const contactFormRef = useRef(null)

  // Stany dla danych agenta oraz widoczności numeru telefonu
  const [agent, setAgent] = useState(null)
  const [showPhone, setShowPhone] = useState(false)

  // Stan formularza kontaktowego
  const [contactForm, setContactForm] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  })

  // Stan statusu wysyłania formularza
  const [formStatus, setFormStatus] = useState({
    submitted: false,
    success: false,
    message: '',
  })

  // Inicjalizacja stanu ofert z reducerem
  const [offersState, dispatchOffers] = useReducer(offersReducer, {
    offers: [],
    totalCount: 0,
    currentPage: 1,
    pageSize: 6, // Liczba ofert na stronę
    loading: true,
  })

  // Funkcja pobierająca dane – oferty agenta oraz informacje o agencie
  const fetchAgentData = async () => {
    try {
      // Pobierz dane agenta z JSON
      setAgent(getAgentFromId(agentId))

      // Pobierz oferty agenta z API z paginacją
      await fetchAgentOffers(offersState.currentPage)
    } catch (error) {
      console.error(t('agentProfile.errorFetchingAgentData'), error)
    }
  }

  // Osobna funkcja do pobierania ofert z paginacją
  const fetchAgentOffers = async (page) => {
    dispatchOffers({ type: 'setLoading', payload: true })

    try {
      const offersResponse = await API.getAgentOffers(offersState.pageSize, page, agentId)

      dispatchOffers({
        type: 'setOffers',
        payload: {
          data: offersResponse.data.response,
          count: offersResponse.data.count || offersResponse.data.response.length,
        },
      })
    } catch (error) {
      console.error(t('agentProfile.errorFetchingAgentOffers'), error)
      dispatchOffers({ type: 'setLoading', payload: false })
    }
  }

  // Efekt dla początowego ładowania danych
  useEffect(() => {
    fetchAgentData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agentId])

  // Efekt dla zmiany strony paginacji
  useEffect(() => {
    fetchAgentOffers(offersState.currentPage)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offersState.currentPage])

  // Funkcje obsługi paginacji
  const handlePageChange = (page) => {
    dispatchOffers({ type: 'changePage', payload: page })
    window.scrollTo({
      top: document.getElementById('offersSection').offsetTop - 100,
      behavior: 'smooth',
    })
  }

  // Przełączanie widoczności numeru telefonu
  const toggleShowPhone = () => {
    setShowPhone((prev) => !prev)
  }

  // Przeniesienie na stronę z wszystkimi ofertami agenta
  const handleSeeMoreOffers = () => {
    navigate(`/agent/${agentId}/all-offers`)
  }

  // Obsługa zmiany pól formularza
  const handleFormChange = (e) => {
    const { name, value } = e.target
    setContactForm((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  // Obsługa wysyłania formularza
  const handleSubmitForm = async (e) => {
    e.preventDefault()

    setFormStatus({
      submitted: true,
      success: false,
      message: t('agentProfile.formSending'),
    })

    try {
      // Przygotuj dane formularza do wysłania
      const formData = {
        ...contactForm,
        agentId: agentId,
        subject: `${t('agentProfile.formTitle', { agentName: agent?.name || 'Aprest' })}`,
      }

      // Wywołaj API do wysłania wiadomości do agenta
      await API.sendAgentPersonalEmailRequest(agentId, formData)

      setFormStatus({
        submitted: true,
        success: true,
        message: t('agentProfile.formSuccess'),
      })

      // Resetowanie formularza po pomyślnym wysłaniu
      setContactForm({
        name: '',
        email: '',
        phone: '',
        message: '',
      })
    } catch (error) {
      console.error(t('agentProfile.formError'), error)
      setFormStatus({
        submitted: true,
        success: false,
        message: t('agentProfile.formError'),
      })
    }
  }

  // Renderowanie przycisków paginacji
  const renderPagination = () => {
    const totalPages = Math.ceil(offersState.totalCount / offersState.pageSize)
    if (totalPages <= 1) return null

    const currentPage = offersState.currentPage
    let pages = []

    if (totalPages <= 5) {
      // Jeśli jest 5 lub mniej stron, pokaż wszystkie
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i)
      }
    } else {
      // Zawsze pokazuj pierwszą stronę
      pages.push(1)

      // Środkowe strony
      if (currentPage <= 3) {
        pages.push(2, 3, 4)
      } else if (currentPage >= totalPages - 2) {
        pages.push(totalPages - 3, totalPages - 2, totalPages - 1)
      } else {
        pages.push(currentPage - 1, currentPage, currentPage + 1)
      }

      // Zawsze pokazuj ostatnią stronę
      if (!pages.includes(totalPages)) {
        pages.push(totalPages)
      }
    }

    // Dodaj elipsy jeśli są luki w numeracji
    const pagesWithEllipsis = []
    pages.forEach((page, index) => {
      if (index > 0 && page - pages[index - 1] > 1) {
        pagesWithEllipsis.push('...')
      }
      pagesWithEllipsis.push(page)
    })

    return (
      <PaginationContainer>
        <PageButton
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          &laquo;
        </PageButton>

        {pagesWithEllipsis.map((page, index) =>
          page === '...' ? (
            <span
              key={`ellipsis-${index}`}
              style={{ alignSelf: 'center' }}
            >
              ...
            </span>
          ) : (
            <PageButton
              key={`page-${page}`}
              active={currentPage === page}
              onClick={() => handlePageChange(page)}
            >
              {page}
            </PageButton>
          ),
        )}

        <PageButton
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          &raquo;
        </PageButton>
      </PaginationContainer>
    )
  }

  if (offersState.loading && !agent) {
    return <LoadingContainer>{t('common.loading')}</LoadingContainer>
  }

  return (
    <PageContainer>
      {/* Profil agenta */}
      <ProfileHeader>
        <ProfileContainer>
          <AgentImageSection>
            <AgentImage
              src={`https://onitsoft-static-apps.s3.eu-central-1.amazonaws.com/aprest-nieruchomosci/agents/${agentId}.webp`}
              alt={agent?.name || 'Agent'}
            />
          </AgentImageSection>

          <AgentDetailsSection>
            <AgentRole>{agent?.role || t('agentProfile.defaultRole')}</AgentRole>
            <AgentName>{agent?.name}</AgentName>

            <ContactRow>
              <AgentPhone>
                {showPhone ? agent?.phone || '+48 000 000 000' : '+48 XXX XXX XXX'}
              </AgentPhone>
              <ShowNumberButton onClick={toggleShowPhone}>
                {showPhone ? t('agentProfile.hideNumber') : t('agentProfile.showNumber')}
              </ShowNumberButton>
            </ContactRow>

            <AgentEmail>{agent?.email || 'agent@aprest.pl'}</AgentEmail>

            <ContactButton
              href='/#agent-message'
              onClick={() =>
                contactFormRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
              }
            >
              {t('agentProfile.contactMe')}
            </ContactButton>

            <Divider />

            {/* Opis agenta */}
            <AgentBio>
              <h4>{agents[agentId]?.descriptions?.[i18next.language]?.paragraphFirst}</h4>
              <p>{agents[agentId]?.descriptions?.[i18next.language]?.paragraphSecond}</p>
              <p>{agents[agentId]?.descriptions?.[i18next.language]?.paragraphThree}</p>
            </AgentBio>
          </AgentDetailsSection>
        </ProfileContainer>
      </ProfileHeader>

      {/* Oferty agenta */}
      <OffersSection id='offersSection'>
        <SectionTitle>{t('agentProfile.agentOffers')}</SectionTitle>

        {offersState.loading ? (
          <LoadingContainer>{t('agentProfile.loadingOffers')}</LoadingContainer>
        ) : offersState.offers.length > 0 ? (
          <>
            <OffersGrid>
              {offersState.offers.map((offer) => (
                <OfferCard
                  key={offer.id}
                  info={offer}
                />
              ))}
            </OffersGrid>

            {/* Paginacja ofert */}
            {renderPagination()}

            {/* Przycisk do przejścia na stronę ze wszystkimi ofertami */}
            <SeeAllButton onClick={handleSeeMoreOffers}>
              {t('agentProfile.seeAllOffers')}
            </SeeAllButton>
          </>
        ) : (
          <p style={{ textAlign: 'center', margin: '30px 0' }}>{t('agentProfile.noOffers')}</p>
        )}
      </OffersSection>

      {/* Formularz kontaktowy */}
      <ContactFormSection ref={contactFormRef}>
        <SectionTitle id='agent-message'>{t('agentProfile.contactAgent')}</SectionTitle>

        <ContactForm onSubmit={handleSubmitForm}>
          <FormTitle>
            {t('agentProfile.formTitle', { agentName: agent?.name || 'Aprest' })}
          </FormTitle>

          <FormRow>
            <FormGroup>
              <FormLabel>{t('agentProfile.formNameLabel')}</FormLabel>
              <FormInput
                type='text'
                name='name'
                value={contactForm.name}
                onChange={handleFormChange}
                required
              />
            </FormGroup>

            <FormGroup>
              <FormLabel>{t('agentProfile.formEmailLabel')}</FormLabel>
              <FormInput
                type='email'
                name='email'
                value={contactForm.email}
                onChange={handleFormChange}
                required
              />
            </FormGroup>
          </FormRow>

          <FormGroup style={{ marginBottom: '20px' }}>
            <FormLabel>{t('agentProfile.formPhoneLabel')}</FormLabel>
            <FormInput
              type='tel'
              name='phone'
              value={contactForm.phone}
              onChange={handleFormChange}
            />
          </FormGroup>

          <FormGroup style={{ marginBottom: '30px' }}>
            <FormLabel>{t('agentProfile.formMessageLabel')}</FormLabel>
            <FormTextarea
              name='message'
              value={contactForm.message}
              onChange={handleFormChange}
              required
            />
          </FormGroup>

          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <SubmitButton type='submit'>{t('agentProfile.formSubmit')}</SubmitButton>

            {formStatus.submitted && (
              <div
                style={{
                  color: formStatus.success ? '#27ae60' : '#e74c3c',
                  marginLeft: '20px',
                }}
              >
                {formStatus.message}
              </div>
            )}
          </div>
        </ContactForm>
      </ContactFormSection>
    </PageContainer>
  )
}

export default AgentProfile
